import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { fetchMenuIsOpen } from "../../actions";
import { Link } from "react-router-dom";
import { menuReaction } from "../helpersScripts/menuReact";

const NavLinks = ({ links, styling, menuOpen, fetchMenuIsOpen, textAlignStyle }) => {
  const location = useLocation();
  const path = location.pathname.split("/");
  const slug = path.pop();
  const ref = useRef([]);

  useEffect(() => {
    document.body.addEventListener(
      "click",
      (event) => {
        menuReaction(event, fetchMenuIsOpen, ref, menuOpen);
      },
      {
        capture: true,
      }
    );

    return () => {
      document.body.removeEventListener(
        "click",
        (event) => {
          menuReaction(event, fetchMenuIsOpen, ref, menuOpen);
        },
        {
          capture: true,
        }
      );
    };
  }, []);

  return (
    <React.Fragment>
      <ul className={`${textAlignStyle} nav flex-column  md-12`}>
        {links.map((el, i) => {
          return (
            <li className="nav-item" key={i}>
              <Link
                to={el.link}
                ref={(el) => (ref.current[i] = el)}
                className={`nav-link nav-top-text ${styling} ${el.link.split("/").pop() === slug ? "text-focus opacity-75" : "text-white"}`}
              >
                {el.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menuOpen,
  };
};

export default connect(mapStateToProps, { fetchMenuIsOpen })(NavLinks);
