import FormSelector from "../../Forms";
import exposureTile from "../../injuryLitigationTypes/tiles/exposure";

const toxicExposure = {
  slug: "toxic-exposure",
  link: "/practice-areas/toxic-exposure",
  image: "/img/PASlider/toxicExposure.jpg",
  imgAlt: "image of smoke leaving an industrial smoke stack",
  imageYAlign: "70",
  title: "Toxic Exposure",
  infoTitle: "Injury Types",
  infoTiles: [exposureTile],
  showSlider: true,
  showProcess: true,
  showForm: true,
  description:
    "Mold, asbestos, and lead are just some toxic substances that can be found in many areas. It's essential to have an experienced attorney to build an effective case.",
  contentTitle: "",
  content: (
    <div className="row overview ">
      <div className="h2 fw-light mb-4 ">Toxic Exposure Law Firm</div>
      <div className="h4 fw-light mb-4 mt-2">Best Toxic Exposure Lawyers in East Texas</div>
      <p className="fw-light mt-2">
        Many times, you might find yourself in a situation where you are exposed to different chemicals, mold, lead paint, or even asbestos.
        Knowing what to do in the event of exposure to toxic substances is key to starting the healing process correctly. A wide range of
        harm may stem from toxic exposure, including cancer, infections, burns, or even birth defects. The claims resulting from this
        exposure are often complex, involving building and construction issues, technical research, and consultations with experts,
        scientists, and doctors. The attorneys at Negem & Worthington have experience in working cases of this nature and are here to help
        answer any questions you might have.
      </p>
      <h4 className="fw-light mt-4 ">Asbestos</h4>
      <p className="fw-light mt-2">
        In the past, asbestos was used in abundance: the fibers were mixed with cement, used in insulation and wiring, and even woven into
        fabric. Unfortunately, the inhalation of these fibers can cause serious illnesses, including malignant lung cancer, mesothelioma,
        and asbestosis. Despite its harmful effects on human health, asbestos is still not entirely banned; rather, the Occupational Safety
        and Health Administration (OSHA) has placed limits on the amount and length of fibers that can be used. If you or a loved one has
        sustained improper asbestos exposure, our attorneys can help you receive the compensation you deserve from the parties who are
        responsible for endangering your health.
      </p>
      <h4 className="fw-light mt-4 ">Mold</h4>
      <p className="fw-light mt-2">
        Mold, mildew, and fungus can contaminate a building’s carpet, walls, and air duct system. Such infestation is sometimes referred to
        as “sick building syndrome,” and is the focus of a growing number of lawsuits across the country. While not all molds are hazardous,
        some contain poisonous substances that lead to adverse health effects, such as:
      </p>
      <div className="ms-md-5 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>Muscle cramps</li>
          <li>Dizziness</li>
          <li>Fatigue</li>
          <li>Shortness of breath</li>
          <li>Sore throat</li>
          <li>Sinus congestion</li>
        </ul>
      </div>
      <h4 className="fw-light mt-4 ">Lead Paint</h4>
      <p className="fw-light mt-2">
        Although banned in the United States for use in residential and commercial buildings as a wall covering, lead-based paint continues
        to persist in the environment, particularly in poor neighborhoods. If your landlord fails to remove or cover old lead paint in your
        apartment, he or she may be liable for your ailments caused by exposure to the harmful paint. Symptoms include:
      </p>
      <div className="ms-md-5 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>Abdominal pain</li>
          <li>Headache</li>
          <li>Anemia</li>
          <li>irritability</li>
        </ul>
      </div>
      <h4 className="fw-light mt-4 ">Pesticides</h4>
      <p className="fw-light mt-2">
        It is almost impossible to avoid all contact with harmful pesticides because they are so frequently used inside and outside of
        homes, offices, schools, recreation areas, and most public buildings. Every year more than 100 million pounds of pesticides are
        applied to lawns alone. Even small amounts of some pesticides can cause eye and skin irritations, damage to the nervous, hormonal,
        and immune systems, cancer, and even death. Also, some pesticides are known to cause birth defects and miscarriages, and others can
        decrease fertility in both men and women.
      </p>

      <p className="fw-light mt-5"></p>
    </div>
  ),
};

export default toxicExposure;
