import React, { useEffect, useRef } from "react";
import NavLinks from "./NavLinks";

const FooterMobileNavLinks = ({ headerLink, bottomLinks }) => {
  const ref = useRef();
  const refArrow = useRef();
  const refSlider = useRef();

  const handleClick = (event) => {
    if (ref.current.contains(event.target)) {
      refArrow.current.classList.toggle("rotate");
      refSlider.current.classList.toggle("close");
      return;
    }
    refArrow.current.classList.remove("rotate");
    refSlider.current.classList.remove("close");
  };

  useEffect(() => {
    document.body.addEventListener("click", handleClick, { capture: true });

    return () => {
      document.body.addEventListener("click", handleClick, { capture: true });
    };
  }, [ref]);

  return (
    <React.Fragment>
      <div ref={ref} className="mt-5 text-start d-flex justify-content-between me-3 " style={{ cursor: "pointer" }}>
        <NavLinks links={headerLink} styling="footer-links" textAlignStyle="text-start" />
        <div className="footerNavLink-arrow" ref={refArrow}></div>
      </div>
      <div className="footer-line w-100" />
      <div className="mt-3 footerNavLink-slider  " ref={refSlider}>
        <NavLinks links={bottomLinks} styling="footer-links" textAlignStyle="text-start" />
      </div>
    </React.Fragment>
  );
};
export default FooterMobileNavLinks;
