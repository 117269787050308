import React from "react";
import { connect } from "react-redux";
import MenuIcon from "./MenuIcon";
import SearchIcon from "./SearchIcon";

import CallUs from "./CallUs";
import PhoneIcon from "./PhoneIcon";

const NavInfo = ({ menuOpen }) => {
  return (
    <div className="nav-info-container  ">
      <div className="nav-inner-container d-flex justify-content-end">
        <div className={` d-none d-md-flex nav-element-hidden ${menuOpen ? "out" : "in"} `}>
          <CallUs />
          <SearchIcon />
        </div>
        <div className="d-block d-sm-none mt-n1 me-3">
          <PhoneIcon />
        </div>
        <MenuIcon />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menuOpen,
  };
};

export default connect(mapStateToProps)(NavInfo);
