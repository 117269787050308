export const FindContent = (content) => {
  if (!content) return;

  if (typeof content === "string") {
    return content;
  }

  if (content.props) {
    return FindContent(content.props.children);
  }

  if (typeof content !== "string" && content.length <= 1) {
    return FindContent(content[0]);
  } else if (typeof content !== "string" && content.length > 1) {
    let found = "";
    let i = 0;
    do {
      found = "";
      found = FindContent(content[i]);
      i++;
    } while (!found || (found && found.length < 60));

    return found;
  }
  return content;
};

export default FindContent;
