export const videos = {
  slug: "videos",
  image: "/img/courtroom.jpg",
  titlePosition: "cetner",
  imageYAlign: 0,
  imgAlt: "image of person writing on paper",
  title: "Videos",
  description: "A page containing all of our videos",
  link: "/vidoes",
  form: "DiscussYourCase",
  formTopPosition: true,

  content: [],
};
