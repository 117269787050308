import { normalizePhone, required, isEmail, maxLength } from "../../helpersScripts/validationFunctions";

export default class FormSelction {
  selector;

  constructor(selector = null) {
    this.selector = selector;
    if (!selector) return;
    this.returnSelection();
  }

  returnSelection() {
    switch (this.selector) {
      case "DiscussYourCase":
        return discussYourCaseForm;
      default:
        return undefined;
    }
  }
}

export const discussYourCaseForm = {
  title: "Discuss Your Case",
  tagLine: "A compassionate, experienced team is what you want by your side after an accident. Let us provide a free consultation today.",
  reCAPTCHALine: "This site is protected by reCAPTCHA",
  fields: [
    {
      name: "firstName",
      label: "First Name",
      inputType: "input",
      type: "text",
      required: true,
      validationFn: [required("Please enter your first name"), maxLength(20)],
    },
    {
      name: "lastName",
      label: "Last Name",
      inputType: "input",
      type: "text",
      required: true,
      validationFn: [required("Please enter your last name"), maxLength(20)],
    },
    {
      name: "email",
      label: "Email",
      inputType: "input",
      type: "email",
      required: true,
      validationFn: [required("Please enter your email"), isEmail],
    },
    {
      name: "phone",
      label: "Phone",
      inputType: "input",
      type: "text",
      normalizeFn: normalizePhone,
      validationFn: [required("Please enter your phone number")],
    },
    {
      name: "message",
      label: "Tell Us About Your Potential Case",
      inputType: "textArea",
      required: true,
      validationFn: [required("Please let us know about your case.")],
    },
  ],
};
