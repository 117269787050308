import axios from "axios";

export default class YouTubeService {
  _api_key = "AIzaSyB2NvffB76vUrwoira5MkNnBkhgwWfCbrQ";
  _playlist = "PLaGwuQy0yEsIolNOdbNFjJfEmw399W9Df";
  MAX_VID_NUM = 50;

  _getURL(id, pageToken) {
    let newPageToken = pageToken != null ? "&pageToken" + pageToken : "";
    return (
      "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=" +
      this.MAX_VID_NUM +
      newPageToken +
      "&playlistId=" +
      id +
      "&key=" +
      this._api_key
    );
  }

  async requestPlayList(pageToken) {
    return await axios.get(this._getURL(this._playlist, pageToken));
  }

  // async getTranscript(videoId) {
  //   const response = await axios.get(`https://www.captionsgrabber.com/8302/get-captions.00.php?id=UJTY7ilwSq4`, {
  //     headers: {
  //       "connect-src": "self",
  //     },
  //   });

  // const data = await response.data;
  // const transcript = data.items[0].id;

  // const transResponse = await axios.get(`https://www.googleapis.com/youtube/v3/captions/${transcript}&key=${this._api_key}`);
  // }
}
