import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

const Video = ({ videos }) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const location = useLocation();
  const path = location.pathname.split("/");
  const slug = path.pop();

  const [windowWidth, setWindowWidth] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth < 1024 ? window.innerWidth : 1024);

    setVideoWidth((windowWidth * 1) | 0);
    setVideoHeight((windowWidth * 0.467) | 0);
  };

  // const previousVideo = videos ? videos.filter((el, i) => console.log(el)) : null;

  let [url, setUrl] = useState("");
  const goBack = () => {
    navigate(`/videos`);
  };

  useEffect(() => {
    setUrl(`https://www.youtube.com/embed/${slug}`);
  }, [slug]);

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  });

  return (
    <div class="container p-md-5 pb-5" style={{ maxWidth: 1024 }}>
      <button class="btn btn-primary mb-2" onClick={goBack}>
        back
      </button>
      <div class="d-flex justify-content-center  p-md-5 ">
        <iframe ref={ref} src={url} height={videoHeight} width={videoWidth} title={slug}></iframe>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    videos: state.videos,
  };
};

export default connect(mapStateToProps)(Video);
