export const practiceAreas = {
  slug: "practice-areas",
  image: "/img/practice-areas.webp",
  imageYAlign: 0,
  imgAlt: "image of person writing on paper",
  title: "Our Practice Areas",
  description:
    "When an unexpected event happens, there are many questions that can become overwhelming. You can feel comfortable talking about the details of your case with us because everything is private and confidential. You can count on us to explain all the options you have and how you will be involved from our first meeting throughout the entire case ...",
  link: "/practice-areas",
  form: "DiscussYourCase",
  formTopPosition: true,
  content: [
    "When an unexpected event happens, there are many questions that can become overwhelming. You can feel comfortable talking about the details of your case with us because everything is private and confidential. You can count on us to explain all the options you have and how you will be involved from our first meeting throughout the entire case.",
    "Negem & Worthington is a dedicated injury law firm in the East Texas area representing injury victims and their families for more than 25 years. Our attorneys have decades of experience helping those hurt in all types of accidents and getting the treatment those clients require and compensation they deserve. Our dedication to delivering the outcomes our clients deserve bring us our strength, longevity, and reputation.",
  ],
};
