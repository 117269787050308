import axios from "axios";

/*
This file processes the information for the form and organizes it into what will be
sent to the mailserver.

Everything needs to be formatted correctly before it comes in to this FormMailer
 - formInfo - this is the information about 
*/

export default class FormMailer {
  _formInfo = {};
  _formData = {};
  _location = "";
  _baseURL = "";
  _formObject = {};
  _mailTo = process.env.REACT_APP_MAIL_TO;

  constructor(formInfo, formData) {
    this._formInfo = formInfo;
    this._location = window.location.hostname;
    // this._baseURL = "https://www.negemlaw.com";
    this._formData = formData;

    if (!this._formInfo || !this._formData) return 1;
    this.createObject();
    return this;
  }

  createObject() {
    if (!this._formData && !this._formInfo) return 0;

    this._formInfo.fields.forEach((el) => {
      this._formObject[el.name] = this._object(el.label, this._formData[el.name]);
    });

    this._formObject["mailTo"] = this._mailTo;
    this._formObject["title"] = this._formInfo.title;
  }

  async sendContactForm() {
    const response = await axios
      .post(this._baseURL + process.env.REACT_APP_ROUTE, {
        // headers: {
        //   "Access-Control-Allow-Headers": "X-Requested-With,content-type",
        //   "Access-Control-Allow-Credentials": "true",
        // },
        data: {
          ...this._formObject,
        },
      })
      .then((res) => {
        return { status: res.status, message: res.data.message };
      })
      .catch((err) => {
        return { status: 500, message: err.message ? err.message : "Network error: Please try again later." };
      })
      .then((res) => res);

    return response;
  }

  _object = (formLabel, formData) => {
    return {
      label: formLabel,
      data: formData,
    };
  };
}
