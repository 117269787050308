import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import { fetchSearchTerm } from "../../actions";
import PageDataController from "../site-data/components/PageDataController";

import FindContent from "../helpersScripts/FindContent";

const SearchResults = ({ term }) => {
  let searchDataArray = [];

  !term ? (searchDataArray = []) : (searchDataArray = new PageDataController().returnSearch(term));

  document.title = "N&W | Search Results";

  const termsDisplay = searchDataArray.map((el, i) => {
    if (!term || term.length > 3) {
      const description = term && term.length > 3 ? FindContent(el.content).substring(0, 256) + " ..." : null;

      return (
        <Link to={el.link} key={el.link} className=" d-flex mb-5">
          <div
            className="pt-3 fw-bold fs-4 text-center text-black"
            style={{ width: "50px", height: "177px", fontFamily: "Montserrat,sans-serif" }}
          >
            {i + 1}.
          </div>
          <div className="bg-white w-100 p-3 shadow">
            <div className="text-black fs-4 fw-normal mb-2">{el.title}</div>
            <p className="text-black fs-5 fw-light">{description}</p>
          </div>
        </Link>
      );
    }
    return "";
    // const lengthOf = description.length < 60 ? description.length : 128;
    // console.log("result ", lengthOf);
  });

  return (
    <div className="container-fluid bg-lightGrey">
      <div className="row bg-primary">
        <div
          className="container-xl text-white ps-4 ps-xl-5 ps-xxl-0 heroText text-center"
          style={{
            paddingTop: "18rem",
            paddingBottom: "4rem",
            fontFamily: "PT Serif Caption,serif",
            zIndex: "1",
            textShadow: "3px 3px 12px rgba(0,0,0,0.3)",
          }}
        >
          Search Results
        </div>
      </div>
      <div className=" bg-lightGrey container pt-5 pb-5" style={{ maxWidth: "1150px" }}>
        <div className="col fw-light fs-5 mb-5 ">
          Showing <span className="fw-bold">{term && term.length > 3 ? searchDataArray.length : 0} Results</span> for "
          <span className="text-primary fw-bold">{term}</span>"...
        </div>

        <div>{termsDisplay}</div>

        <div className="row">
          <div></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { term: state.term };
};

export default connect(mapStateToProps)(SearchResults);
