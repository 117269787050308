import React, { useEffect, useRef, useState } from "react";

const Flipper = ({ data }) => {

  const itemsRef = useRef([]);
  const ref = useRef();
  let [currentSelection, setCurrentSelection] = useState(0);
  let [titleDiv, setTitleDiv] = useState("");
  let [contentDiv, setContentDiv] = useState("");

  const changePage = (index) => {
    itemsRef.current[currentSelection].nextSibling.classList.remove("flipper-show");
    titleDiv.textContent = data[index].title;
    contentDiv.textContent = data[index].content;

    setCurrentSelection(index);
    itemsRef.current[index].nextSibling.classList.add("flipper-show");
  };

  useEffect(() => {
    setTitleDiv(ref.current.children[0].children[0]);
    setContentDiv(ref.current.children[0].children[1]);
    if (titleDiv.textContent === "loading...") titleDiv.textContent = data[0].title;
    if (contentDiv.textContent === "loading...") contentDiv.textContent = data[0].content;
    itemsRef.current[currentSelection].nextSibling.classList.add("flipper-show");
  }, [contentDiv, currentSelection, data, titleDiv]);

  return (
    <div className=" d-block d-md-flex mb-5 align-items-center pb-3">
      <div className="col-md-6 mb-4 mb-md-0 ">
        {data.map((el, i) => {
          return (
            <div key={i}>
              <div
                className="pt-4 pb-1 fs-5 fw-light flipper-choice"
                ref={(el) => (itemsRef.current[i] = el)}
                onClick={() => changePage(i)}
              >
                {el.title}
              </div>
              <div className="bg-primary flipper-line" style={{ height: "3px" }}></div>
            </div>
          );
        })}
      </div>

      <div
        ref={ref}
        className="col-md-6 bg-white shadow p-4 d-flex  align-items-center"
        style={{ minHeight: "500px", zIndex: "30", transition: "all .5s ease" }}
      >
        <div className="">
          <h4 className="fw-light mb-3">loading...</h4>
          <p className="fw-light">loading...</p>
        </div>
      </div>
    </div>
  );
};

export default Flipper;
