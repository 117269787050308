import autoAccidents from "./pages/AutoAccidents";
import commercialVehicleAccidents from "./pages/CommercialAccidents";
import oilfieldInjuries from "./pages/OilfieldInjuries";
// import patentCommercialLitigation from "./pages/PatentCommercialLit";
import personalInjury from "./pages/PersonalInjuries";
import seriousInjury from "./pages/SeriousInjuries";
import toxicExposure from "./pages/ToxicExposure";
import wrongfulDeath from "./pages/WrongfulDeath";



import jimmyNegem from "./pages/JimmyNegem";
import joeWorthington from "./pages/JoeWorthington";
import jimmyNegemJr from "./pages/JimmyNegemJr";
import nickNegem from "./pages/NickNegem";
import { videos } from "./pages/videos";
import { privacyPolicy } from "./pages/PrivacyPolicy";


// This is the section where you add the javascript file with all the details about the page
// this will make is propigate everywhere on the site
const pages = {
  practiceAreas: [
    personalInjury,
    oilfieldInjuries,
    wrongfulDeath,
    seriousInjury,
    toxicExposure,
    autoAccidents,
    commercialVehicleAccidents,
    // patentCommercialLitigation,
  ],
  attorneys: [jimmyNegem, joeWorthington, jimmyNegemJr, nickNegem],
  videos: [videos],
  privacyPolicy: [privacyPolicy],
};

const topSectionLinks = {
  practiceAreas: {
    topLinks: [
      {
        sectionTitle: "Overview",
        sectionLink: "overview",
      },
      {
        sectionTitle: "Injury Types",
        sectionLink: "injury_types",
      },
      {
        sectionTitle: "Accidents",
        sectionLink: "accidents",
      },
      // {
      //   sectionTitle: "Results & Education",
      //   sectionLink: "results_education",
      // },
      {
        sectionTitle: "Our Process",
        sectionLink: "our_process",
      },
    ],
  },
  attorneys: {
    topLinks: [
      {
        sectionTitle: "Meet",
        sectionLink: "meet",
      },
      // {
      //   sectionTitle: "Experience",
      //   sectionLink: "experience",
      // },
      {
        sectionTitle: "Education",
        sectionLink: "education",
      },
      {
        sectionTitle: "Admissions",
        sectionLink: "admissions",
      },
      {
        sectionTitle: "Professional Affiliations",
        sectionLink: "professional_affiliations",
      },
    ],
  },
};

export const contentPages = (type, pageNeeded = null) => {
  // console.log("type ", type, "page ", pageNeeded);

  if (!type && !pageNeeded) return returnAllPages();

  // if (type !== null && pageNeeded === null) {
  // }
  if (pages[type].length === 1) return pages[type][0];
  const page = pages[type].filter((page) => (!pageNeeded || pageNeeded === page.slug ? { data: page } : null));
  // console.log(pageNeeded);
  return page.length === 1 ? { ...page[0], ...topSectionLinks[type] } : page;
};;

export const returnAllPages = () => {
  let allPages = [];
  for (let key of Object.entries(pages)) {
    allPages = [...allPages, ...key[1].map((el) => el)];
  }
  return allPages;
};
