import FormSelector from "../../Forms";
import paralysisTile from "../../injuryLitigationTypes/tiles/paralysis";
import severBurnsTile from "../../injuryLitigationTypes/tiles/severeBurns";
import spinalInjuriesTile from "../../injuryLitigationTypes/tiles/spinalInjuries";
import wrongfulDeathTile from "../../injuryLitigationTypes/tiles/wrongfulDeath";

const seriousInjury = {
  slug: "serious-injuries",
  link: "/practice-areas/serious-injuries",
  image: "/img/PASlider/seriousInjury.jpg",
  imgAlt: "image of person being wheeled from ambulance on gurney by parametic",
  imageYAlign: "70",
  title: "Serious Injury",
  description:
    "Injuries involving the brain and spinal cord can be life-altering for the victim and their family. Find out how we can help you achieve justice.",
  infoTitle: "Injury Types",
  infoTiles: [paralysisTile, spinalInjuriesTile, wrongfulDeathTile, severBurnsTile],
  showSlider: true,
  showProcess: true,
  showForm: true,
  contentTitle: "",

  // this is the content of the page in JSX
  content: (
    <div className="row overview ">
      <div className="h2 fw-light mb-4 ">Serious Injuries Law Firm</div>
      <div className="h4 fw-light mb-4 mt-2">Best Serious Injuries Lawyers in East Texas</div>
      <p className="fw-light mt-2">
        Everyone’s quality of life is important. Being able to complete simple tasks can be taken for granted; but, after a serious injury
        caused by a negligent party, you might find yourself unable to complete these simple tasks. Daily life in general can become hard
        without help from others. On another hand, you might have a loved one who is going through these same troubles. The law firm of
        Negem & Worthington represents those who have suffered serious injuries due to the negligence of others. We help clients seek
        compensation for major injuries including spinal cord injury, traumatic brain injury and severe burns. We are dedicated to helping
        our clients receive compensation for the physical, emotional, and financial harm they have suffered. If you or a loved one has
        suffered a serious or catastrophic injury, you need an attorney with the experience and the determination to get you justice.
      </p>

      <h4 className="fw-light mt-5 ">I Have a Spinal Core Injury, What Should I Do?</h4>
      <p className="fw-light mt-3">
        Don’t feel like there’s anyone there to listen and help you. Our attorneys can help you obtain the compensation you need to pay for
        the costs associated with spinal cord injuries. SCI can occur when a traumatic force tears or bruises the spinal cord. Severe SCIs
        result in loss of feeling and functionality of the limbs, and in some cases, severe respiratory problems. The most serious SCIs may
        lead to paralysis, including paraplegia, which affects the lower extremities, and quadriplegia or tetraplegia, which affects all
        four limbs and the chest, neck, and head. SCIs require a lifetime of care and treatment, which many families simply cannot afford.
        Let us help you or your family member get the compensation owed to you.{" "}
      </p>
      <div className="ms-md-5 mt-2 fw-light">
        <p>Quite a few accidents are associated with SCIs including:</p>
        <ul className="mb-4 list">
          <li>Car, truck, and commercial vehicle accidents</li>
          <li>Construction site accidents</li>
          <li>Falling on the neck or back</li>
          <li>Birth injuries</li>
          <li>Sports injuries</li>
        </ul>
      </div>
      <h4 className="fw-light mt-5 ">What Should I Do If a Loved One Suffers Traumatic Brain Injury?</h4>
      <p className="fw-light mt-2">
        When a loved one is in an accident that causes a traumatic brain injury (TBI), it can be hard to afford treatment and struggle with
        their lost income. At the onset of any symptoms, immediately seek medical care and contact an experienced personal injury attorney
        for your best chance of recovery. Traumatic brain injuries occurs when a sudden physical impact or shaking damages the brain.
        Symptoms of TBI may vary depending on the severity of the injury and the part of the brain that was affected. TBIs range from minor
        concussions or contusions to hemorrhages to injuries causing permanent vegetative state or coma. Due to the range of possible
        symptoms, and because many symptoms are not always immediately apparent, TBIs are sometimes difficult to diagnose, or subject to a
        delayed diagnosis. If you or a loved one has suffered a head injury, it is important to carefully observe, and not brush aside,
        unusual behaviors. Common causes of TBI include vehicle accidents, including car, truck, 18-wheeler, motorcycle, and bicycle
        accidents. They can also be associated with falls, medical malpractice, birth trauma, sports injuries, and assaults as well. Don’t
        wait to contact us for a free consultation to see how the attorneys at Negem & Worthington can help.
      </p>
      <h4 className="fw-light mt-5 ">What Should I do If I Am Severely Burned?</h4>
      <p className="fw-light mt-2">
        Severe burns caused by vehicle accidents, defective products, or dangerous work environments are among the most painful and
        debilitating injuries that a person can sustain. Injuries range from minor first-degree burns to full-thickness third-degree burns
        and even fourth-degree burns that damage muscle or bone. Treatment for severe burns often involves extended hospital stays,
        rehabilitation, and reconstructive surgery. If you or a loved one has suffered severe burns in an accident and required serious
        medical treatment, you need an attorney who can help you seek just compensation.
      </p>
    </div>
  ),
};

export default seriousInjury;
