import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { fetchVideoPage } from "../../actions";

const Pagination = ({ itemsPerPage, itemCount, pageFn, videoPage, fetchVideoPage }) => {
  const ref = useRef([]);
  // let [videoPage, setvideoPage] = useState(1);
  const pagesToShow = 3;
  const numberOfPages = Math.ceil(itemCount / itemsPerPage);

  // calcuales the starting position in the array ant then creates an array for later use
  const pagesCountArray = () => {
    const array = [];

    // calculate how many tabs are available to show
    const numberOfTabs = () => {
      if (numberOfPages < pagesToShow) return numberOfPages;
      return pagesToShow;
    };

    // get the starting position in the array
    const startNumber = () => {
      if (videoPage <= 1) {
        return 1;
      } else if (Number(videoPage) === numberOfPages) {
        if (pagesToShow > numberOfPages) {
          return 1;
        }
        return videoPage - pagesToShow + 1;
      }
      return videoPage - 1;
    };

    // create the tab number array
    for (let i = startNumber(); i < numberOfTabs() + startNumber(); i++) {
      array.push(i);
    }
    return array;
  };

  // handle the tab click
  const handleClick = (event) => {
    const selection = event.target.textContent;

    if (selection === "Next") {
      if (document.getElementById("0").classList.contains("text-primary")) fetchVideoPage(document.getElementById("1").textContent);
      else fetchVideoPage(document.getElementById("2").textContent);
    } else if (selection === "Previous") {
      if (document.getElementById("2") && document.getElementById("2").classList.contains("text-primary"))
        fetchVideoPage(document.getElementById("1").textContent);
      else if (document.getElementById("1").classList.contains("text-primary")) {
        fetchVideoPage(document.getElementById("0").textContent);
      } else fetchVideoPage(document.getElementById("0").textContent);
    } else {
      fetchVideoPage(Number(event.target.textContent));
    }
  };

  useEffect(() => {
    ref.current.forEach((el) => {
      const item = document.getElementById(el.id);
      item.addEventListener("click", (e) => handleClick(e));
      return () => {
        ref.current.forEach((el) => {
          const item = document.getElementById(el.id);
          item.removeEventListener("click", (e) => handleClick(e));
        });
      };
    });
  }, [itemCount]);

  useEffect(() => {
    fetchVideoPage(videoPage);
    pageFn((videoPage - 1) * itemsPerPage);
  }, [videoPage]);

  const renderPagination = () => {
    return (
      <React.Fragment>
        {numberOfPages > 1 ? (
          <ul className="pagination justify-content-center">
            <li className={`page-item ${videoPage <= Math.ceil(pagesToShow / 2) - 1 ? "disabled" : ""}`}>
              <a ref={(el) => (ref.current[pagesToShow] = el)} className="page-link" id="previous" style={{ userSelect: "none" }}>
                Previous
              </a>
            </li>
            {videoPage > Math.ceil(pagesToShow / 2) && numberOfPages > pagesToShow ? (
              <li className="page-item disabled">
                <a className="page-link" href="#" style={{ userSelect: "none" }}>
                  ...
                </a>
              </li>
            ) : (
              ""
            )}
            {pagesCountArray().map((el, i) => {
              return (
                <li key={i} className="page-item">
                  <div
                    ref={(el) => (ref.current[i] = el)}
                    className={`page-link text-${el == videoPage ? "primary" : "secondary"}`}
                    id={i}
                    style={{ userSelect: "none" }}
                  >
                    {el}
                  </div>
                </li>
              );
            })}

            {videoPage < numberOfPages - 1 && numberOfPages > pagesToShow ? (
              <li className="page-item disabled">
                <a className="page-link" href="#" style={{ userSelect: "none" }}>
                  ...
                </a>
              </li>
            ) : (
              ""
            )}

            <li className={`page-item ${videoPage >= numberOfPages ? "disabled" : ""}`}>
              <a ref={(el) => (ref.current[pagesToShow + 1] = el)} className="page-link" id="next" style={{ userSelect: "none" }}>
                Next
              </a>
            </li>
          </ul>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  };

  return <nav aria-label="Page navigation ">{renderPagination()}</nav>;
};

const mapStateToProps = (state) => {
  return {
    videoPage: state.videoPage,
  };
};

export default connect(mapStateToProps, { fetchVideoPage })(Pagination);
