export const topLinksList = [
  {
    title: "About Us",
    link: "/about",
  },
  {
    title: "Practice Areas",
    link: "/practice-areas",
  },
  {
    title: "Attorneys",
    link: "/attorneys",
  },
  {
    title: "Map & Directions",
    link: "/map-and-directions",
  },
  {
    title: "Contact Us",
    link: "/contact-us",
  },
];

export const bottomLinksList = [
  // {
  //   title: "Testimonials",
  //   link: "/testimonials",
  // },
  // {
  //   title: "Results",
  //   link: "/results",
  // },
  {
    title: "Videos",
    link: "/videos",
  },
  // {
  //   title: "News",
  //   link: "/news",
  // },
];
