import React from 'react';
import {Link} from "react-router-dom";

export default function ImageHeader({ image, alt, topGap }) {
    
    const linksPages = [
        {page: "personal-injury", image: "PI"},
        {page: "oilfield-injuries", image: "OA"},
        {page: "wrongful-death", image: "WD"},
        {page: "serious-injuries", image: "SI"},
        {page: "toxic-exposure", image: "TE"},
        {page: "auto-accidents", image: "AA"},
        {page: "commercial-vehicle-accidents", image: "CVA"},
    ]

    if (image === null) {
        return (<div></div>);
    }
    if (alt === null) {
        alt = "Image";
    }
    if (topGap === null) {
        topGap = false;
    }
    
    const writeLinks = () => {
        
        return linksPages.map((link, index) => ([
                <Link className="main-header__link" to={"/practice-areas/" + link.page} key={index}>
                    <img src={`/img/header/${link.image}-normal.png`} alt={alt}/>
                </Link>,
                (index < linksPages.length-1) ? <i className="main-header__divider"><img src={'/img/header/divider.png'} alt={alt}/></i> : ""
            ]
        ));
    }

    return (
        <div>
            {topGap ? (
                    <div className="top-image-banner" >

                </div>
            )
                : ("")}

           
            <div className=" main-header overflow-hidden" >
                <div className={'main-header__background-image'}>
                    <img alt={'Hurt? Click to schedule your free consultation today!'} src={'/img/header/top-nav-header-main.png'} style={{objectFit: 'cover', width: '100%'}}/>
                </div>
                <div className={'main-header__links-container'}>
                    <div className="d-flex  position-relative">  
                        {writeLinks()}
                    </div>
                </div>
                <div className="main-header__link-btn-container">
                    <div className="d-flex  position-relative">
                        <Link className="main-header__link main-header__link-btn" to="/contact-us"><img src="/img/header/click-button.png" alt="Click to schedule your FREE consultation today!"/></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}