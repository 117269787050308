export const privacyPolicy = {
  slug: "privacy-policy",
  link: "/privacy-policy",
  image: "/img/shake.jpg",
  imgAlt: "Negem and Worthington standing for a picture",
  imageYAlign: 35,
  title: "Privacy Policy",
  form: "DiscussYourCase",
  formTopPosition: true,

  showSlider: false,
  showProcess: false,
  showForm: false,
  description: "A brief overview of our privacy policies",
  contentTitle: "",
  content: (
    <div className="row overview ">
      <p className="fw-light mt-2">
        Privacy is a very important subject for us. We developed this Policy for you to understand how we collect, use, and make use of
        personal data.
      </p>
      <p className="fw-light">
        An attorney-client relationship is not created by using the Internet or the email contact form to communicate with the firm or any
        individual member of the firm. Sending confidential or urgent information using this website's email contact forms is not
        recommended. Until the firm establishes that there is no conflict of interest and that it is willing and otherwise able to accept
        the new engagement, regardless of whether you are a new or current client of the firm, Negem & Worthington cannot represent you on a
        new case. Do not email or submit any information to Negem & Worthington until you have been advised that the firm is prepared and
        able to accept your new matter.
      </p>

      <h4 className="fw-light mt-5 ">An Outline of Our Privacy Policy</h4>
      <p className="fw-light mt-3">Negem & Worthington will find out some information about you when you visit our website.</p>
      <p className="fw-light">
        Our website, like other commercial web sites, uses "cookies"—a standard technology—and web server logs to track how visitors use it.
        For more information on what cookies are, see the section below titled "What Are Cookies?" The date and time of visits, the pages
        viewed, the amount of time spent on our website, the websites visited immediately before and immediately after our website, and your
        IP address may all be collected by cookies and web server logs.
      </p>

      <h4 className="fw-light mt-5 ">What are Cookies?</h4>
      <p className="fw-light mt-3">
        A cookie is a very small text file that frequently contains a private, unique identifier. When you visit a website, the computer
        running that website requests permission to keep this file in a location on your hard drive reserved for cookies. If your browser's
        settings permit it, each website may send its own cookie to your browser. However, in order to safeguard your privacy, your browser
        only allows websites to access the cookies that it has already provided to you and not the cookies that have been sent to you by
        other websites.
      </p>

      <h4 className="fw-light mt-5 ">How Do We Utilies the Information You Provide?</h4>
      <p className="fw-light mt-3">
        In general, we use personal information to administer our business operations and deliver client service.
      </p>
      <p className="fw-light">
        Unless you choose to give your data to us, Negem & Worthington will not learn any personally identifying information about you when
        you visit our website, and we will not sell or otherwise transfer that information to unaffiliated third parties without the consent
        of you the user at the time of collection.
      </p>
      <p className="fw-light">
        We may release information if required by law to do so, in other words, where we have a good faith belief that doing so is required
        by the law or is necessary to preserve our legal rights.
      </p>

      <h4 className="fw-light mt-5 ">How Do We Safeguard Your Data and Encrypt Information Transfers?</h4>
      <p className="fw-light mt-3">
        Email is not acknowledged as a secure communication channel. This is why we ask that you refrain from emailing us any private
        information. However, you are free to do so at your own risk. Some of the data you could enter on our website might be sent securely
        via Secure Sockets Layer, or SSL, a secure transmission method. Email is never used to send credit card data.
      </p>
      <p className="fw-light">
        Summary statistics may be generated by software programs used by Negem & Worthington to assess factors like the volume of visitors
        to the various parts of our website, the information that is most and least interesting to users, technical design requirements, and
        system performance or problem areas.
      </p>
      <p className="fw-light">
        Negem & Worthington employs software programs to monitor network traffic in order to identify unauthorized attempts to upload
        material, change information, or otherwise cause harm. This is done for site security considerations and to guarantee that this
        service is still available to all users.
      </p>

      <h4 className="fw-light mt-5 ">Changes to Policies</h4>
      <p className="fw-light mt-3">
        This privacy statement is subject to change at any time, with or without notice. But be assured that even if the Privacy Policy
        changes in the future, we won't use the personal data you've given us under this Privacy Policy in a way that materially violates it
        without first getting your permission.
      </p>
      <p className="fw-light">
        In order to preserve and uphold the confidentiality of personal information, we are dedicated to conducting our business in
        accordance with these guidelines.
      </p>

      <h4 className="fw-light mt-5 ">Contact</h4>
      <p className="fw-light mt-3">
        Please get in touch with us using our contact page if you have any queries about this Policy or your interactions with our website.
      </p>
    </div>
  ),
};

export default privacyPolicy;
