import paralysisTile from "../../injuryLitigationTypes/tiles/paralysis";
import spinalInjuriesTile from "../../injuryLitigationTypes/tiles/spinalInjuries";
import wrongfulDeathTile from "../../injuryLitigationTypes/tiles/wrongfulDeath";
import link from "../links";

const commercialVehicleAccidents = {
  slug: "commercial-vehicle-accidents",
  link: "/practice-areas/commercial-vehicle-accidents",
  image: "/img/PASlider/commercialVehicleAccidents.jpg",
  imgAlt: "image of commercial truck on road",
  imageYAlign: "50",
  title: "Commercial Vehicle Accidents",
  infoTitle: "Injury Types",
  infoTiles: [paralysisTile, spinalInjuriesTile, wrongfulDeathTile],
  showSlider: true,
  showProcess: true,
  showForm: true,
  description:
    "Accidents involving tractor trailers and other heavy commercial vehicles can result in devastating injuries. Don't attempt to face the insurance company alone.",
  contentTitle: "",
  content: (
    <div className="row overview ">
      <div className="h2 fw-light mb-4 ">Commercial Accident Law Firm</div>
      <div className="h4 fw-light mb-4 mt-2">Best Commercial Accident Lawyers in East Texas</div>
      <p className="fw-light mt-2">
        Each year there are roughly 33,000 total commercial vehicle accidents. With restricted visibility and limited ability to maneuver,
        semi-trucks and 18-wheelers are a challenge to operate. Even the most competent driver must work diligently to navigate these
        enormous vehicles and safely share the roads with smaller cars. However, due to their long hours, many commercial truckers suffer
        from fatigue, leading to a high rate of collisions with other vehicles on the road. Other common causes of 18-wheeler accidents
        include:
      </p>
      <div className="ms-md-5 mt-2 fw-light">
        <ul className="mb-4 list">
          <li>Driver inexperience and /or improper training</li>
          <li>Speeding and/or reckless driving</li>
          <li>Driving under the influence or alcohol or drugs (which may be used to stay awake for long periods of time)</li>
          <li>Improperly loaded or oversized vehicles</li>
          <li>Poorly maintained brakes, tires, or safety equipment</li>
        </ul>
      </div>
      <h4 className="fw-light mt-5 mb-4">In the Event of a Commercial Vehicle Accident, What Should I Do?</h4>
      <p className="fw-light mt-2">
        If you or a loved one has been involved in an accident with an 18-wheeler, time is of the essence. To determine whether the truck
        driver or trucking company violated Texas law or any of the safety rules outlined in the {link("Federal Motor Carrier Regulations")}
        , it is imperative to carefully examine the company’s records as soon as possible after an accident. These records often reveal
        serious and sometimes fraudulent violations of safety regulations, such as driving for long periods of time in excess of limits set
        by state and federal law. Our attorneys have an in-depth understanding of the regulations governing the operation of commercial
        vehicles and can gather the information and evidence needed to substantiate your case.
      </p>

      <h4 className="fw-light mt-5 ">What about Compensation and Recovery?</h4>
      <p className="fw-light mt-3">
        If the negligence or recklessness of a driver or trucking company caused your personal injury or the loss of a loved one, you
        deserve to recover for the harm you have suffered. Recoverable damages for injured parties include both economic damages, such as
        medical costs and lost wages, and non-economic damages, such as pain and suffering. Injuries suffered in accidents with commercial
        trucks often require extensive care and hospital stays, and some trucking companies do not have insurance policies that will cover
        the full extent of the costs. Our extensive background in pursuing recovery from both insurance companies and corporate entities
        gives us the ability to obtain the full compensation you deserve, whether the case ends in negotiations or litigation.
      </p>
    </div>
  ),
};

export default commercialVehicleAccidents;
