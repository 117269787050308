export const menuReaction = (event, reduxActionFn, ref, menuState) => {
  const [currentRef] =
    ref.current?.length !== undefined
      ? ref.current.filter((el) => el === event.target)
      : [ref.current];

  if (currentRef && currentRef.contains(event.target)) {
    reduxActionFn(menuState);
    return;
  }
};
