import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import YouTubeService from "../helpersScripts/YouTubeService";
import { connect } from "react-redux";
import { fetchVideos } from "../../actions";
import FormContainer from "./ContactForms/FormContainer";
import VideoTiles from "./VideoTiles";
import Pagination from "./Pagination";
import Video from "./Video";

const Videos = ({ form, videos, fetchVideos }) => {
  const location = useLocation();
  const path = location.pathname.split("/");
  const slug = path.pop();

  const youTubeService = new YouTubeService();
  let [currentStartVideo, setCurrentStartVideo] = useState(0);
  let [visibleVideos, setVisibleVideos] = useState();

  const videoPerPage = 5;

  const firstElement = (current) => {
    setCurrentStartVideo(current);
  };

  const getVideos = async () => {
    const response = await youTubeService.requestPlayList(0);
    const data = await response.data;

    const respVideos = data.items.map((video, i) => {
      return {
        id: video.id,
        publishedAt: new Date(video.snippet.publishedAt),
        title: video.snippet.title,
        description: video.snippet.description,
        image: video.snippet.thumbnails.high.url,
        link: `/videos/${video.snippet.resourceId.videoId}`,
      };
    });

    await fetchVideos(respVideos);

    configureVisibleVideos(respVideos);
  };

  const configureVisibleVideos = (respVideos) => {
    setVisibleVideos(
      Array.from(respVideos).filter((el, i) => {
        if (i >= currentStartVideo && i < currentStartVideo + videoPerPage) {
          return el;
        }
        return null;
      })
    );
  };

  useEffect(() => {
    getVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStartVideo, fetchVideos]);

  //   .then((el) => {
  //     videos = el.data.items.map(async (video) => {
  //       return {
  //         id: video.id,
  //         publishedAt: new DataTransfer(video.snippet.publishedAt),
  //         title: video.snippet.title,
  //         description: video.snippet.description,
  //         imgURL: video.snippet.thumbnails.medium.url,
  //       };
  //     });
  //   });

  if (slug === "videos") {
    return (
      <React.Fragment>
        <div className="container-xl bg-lightGrey" style={{ zIndex: "9999" }}>
          <div className="row">
            <div className="col-lg-7 col-xl-8 px-md-5">
              <VideoTiles tiles={visibleVideos} />

              <Pagination pageFn={firstElement} itemsPerPage={videoPerPage} itemCount={videos.length} />
            </div>

            <div className="col-lg-5 col-xl-4 order-first order-lg-last">
              <div className="sticky-top wm-25 mt-3 mb-3" style={{ top: "215px" }}>
                <FormContainer form={form} />
              </div>
            </div>
          </div>
        </div>
        {/* Section */}

        {/* <div ref={(el) => (scrollRefs.current[1] = el)} className="pb-5">
         <InjuryTypes title={data.infoTitle} data={data.infoTiles} />
       </div> */}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Video />
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    videos: state.videos,
  };
};

export default connect(mapStateToProps, { fetchVideos })(Videos);
