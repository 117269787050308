import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { fetchSearchTerm, fetchSearchMenuState, fetchMenuIsOpen } from "../../actions";

const SearchBubble = ({ term, fetchSearchTerm, fetchSearchMenuState, fetchMenuIsOpen, menuOpen, width }) => {
  const navigate = useNavigate();
  const onInputChange = (event) => {
    fetchSearchTerm(event.target.value);
  };

  const onFormSubmit = (event) => {
    event.preventDefault();

    if (menuOpen) fetchMenuIsOpen(false);
    fetchSearchMenuState(false);
    fetchSearchTerm(term);
    navigate(`/search-results`);
    // need a router link here to goto the search page
  };

  return (
    <div className="search-bubble ">
      <form
        className="input-group"
        onSubmit={onFormSubmit}
        // style={{ width: `${width - 50}px` }}
      >
        <input className="form-control" type="text" placeholder={"Search..."} value={term} onChange={onInputChange} />

        <button type="submit" className="input-group-prepend">
          <img className="input-group-text" src="/img/search-primary.svg" alt="Search button" />
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menuOpen: state.menuOpen,
    term: state.term,
  };
};

export default connect(mapStateToProps, { fetchSearchTerm, fetchSearchMenuState, fetchMenuIsOpen })(SearchBubble);
