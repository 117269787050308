import React, { useEffect, useState, useRef } from "react";
import useWindowDemensions from "../helpersScripts/windowDimensions";
import SliderArrow from "./SliderArrow";
import SliderCard from "./SilderCard";
import { connect } from "react-redux";
import PageDataController from "../site-data/components/PageDataController";

const Slider = ({ data }) => {
  const cardData = new PageDataController().sliderData("practiceAreas");
  const { width } = useWindowDemensions();

  const first = 0;
  let [numberToDisplay = 0] = useState(0);
  let [offSet, setOffset] = useState(width * 0.09);
  let [currentFirst, setCurrentFirst] = useState(first);
  let [currentWidth, setCurrentWidth] = useState(0);
  let [slideAmount, setSlideAmount] = useState(0);
  let [fullSlide, setFullSlide] = useState(0);
  let [cardWidth, setCardWidth] = useState(0);
  let [rightFade, setRightFade] = useState(false);
  let [leftFade, setLeftFade] = useState(true);
  let cardNumber = 0;
  const itemsRef = useRef([]);

  let sliderOffSet = offSet;

  if (width < 400) {
    numberToDisplay = 1;
    sliderOffSet = width * 0.08;
  } else if (width < 576) {
    numberToDisplay = 1;
    sliderOffSet = width * 0.09;
  } else if (width >= 576 && width < 768) {
    numberToDisplay = 1;
    sliderOffSet = width * 0.2;
  } else if (width >= 768 && width < 1200) {
    numberToDisplay = 2;
    sliderOffSet = width * 0.15;
  } else {
    numberToDisplay = 3;
    sliderOffSet = width * 0.1;
  }

  const slideDirection = (rl) => {
    if (rl === "left" && offSet + slideAmount <= parseInt(itemsRef.current[0].offsetWidth, 10)) {
      setOffset(offSet + slideAmount);
      setCurrentFirst(currentFirst - numberToDisplay);
      return;
    } else if (rl === "right" && offSet > -fullSlide + slideAmount) {
      setOffset(offSet - slideAmount);
      setCurrentFirst(currentFirst + numberToDisplay);
      return;
    }
    return;
  };

  useEffect(() => {
    setCardWidth(Math.floor(parseInt(itemsRef.current[0].offsetWidth, 10)));
    setFullSlide(cardWidth * (width < 576 ? cardNumber - 1 : cardNumber - 1));
    setSlideAmount(cardWidth * numberToDisplay);

    //// controls arrow visability
    currentFirst === 0 ? setLeftFade(true) : setLeftFade(false);
    cardNumber <= currentFirst + numberToDisplay ? setRightFade(true) : setRightFade(false);

    ///// reset offset on window resize
    if (currentWidth !== width) {
      if (currentFirst % 3 !== 0 && numberToDisplay === 3) {
        let result = currentFirst % 3;
        result = result < 1.5 ? currentFirst + result : result + 1;
        setCurrentFirst(result);
      } else if (currentFirst % 2 !== 0 && numberToDisplay === 2) {
        let result = Math.floor(currentFirst / 2);
        setCurrentFirst(currentFirst - result);
      }
      setOffset(sliderOffSet - currentFirst * cardWidth);
      setCurrentWidth(width);
    }
  }, [cardWidth, width, cardNumber, offSet, numberToDisplay, currentFirst, currentWidth, sliderOffSet]);

  const renderList = cardData.map((card, index) => {
    cardNumber++;
    return (
      <div
        ref={(el) => (itemsRef.current[index] = el)}
        key={card.link}
        className=" col-xl-3 col-lg-4 col-md-4 col-sm-7 col-10 "
        style={{ height: "425px", opacity: index < currentFirst || index >= currentFirst + numberToDisplay ? "0.5" : "1" }}
      >
        <SliderCard card={card} />
      </div>
    );
  });

  return (
    <div className="w-100 overflow-hidden ">
      <SliderArrow callBack={slideDirection} direction={"left"} fade={leftFade} />
      <SliderArrow callBack={slideDirection} direction={"right"} fade={rightFade} />
      <div className="d-flex h-100" style={{ transform: `translateX(${offSet}px)`, transition: "all .3s ease" }}>
        {renderList}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(Slider);
