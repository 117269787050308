import React from "react";
import useWindowDemensions from "../helpersScripts/windowDimensions";

const SliderArrow = ({ direction, callBack, fade }) => {
  const { width } = useWindowDemensions();

  const leftOrRight = () => {
    if (!direction && (direction !== "left" || direction !== "right")) return;

    const breakPoint = 576;
    const arrowCirclePosition = width < breakPoint ? ".5" : "3.1";
    const leftArrowPosition = width < breakPoint ? "-.5rem, -2.8rem" : "1.3rem, -4.6rem";
    const rightArrowPosition = width < breakPoint ? "-1.7rem, -1.6rem" : "-3.5rem, 0.2rem";
    // arrow: "rotate(45deg) translate(1.3rem, -4.6rem)",
    if (direction === "left") {
      return {
        position: "start-0",
        circle: `translateX(${arrowCirclePosition}rem)`,
        arrow: `rotate(45deg) translate(${leftArrowPosition})`,
        arrowClass: "border-bottom border-start",
      };
    }

    return {
      position: "end-0",
      circle: `translateX(-${arrowCirclePosition}rem)`,
      arrow: `rotate(45deg) translate(${rightArrowPosition})`,
      arrowClass: "border-top border-end",
    };
  };

  return (
    <div
      className={`position-absolute ${leftOrRight().position}`}
      style={{ transform: "translateY(212px)", zIndex: "1000", opacity: fade ? ".2" : "1" }}
      onClick={() => callBack(direction)}
    >
      <div
        className={`bg-white rounded-circle `}
        style={{ width: "50px", height: "50px", transform: leftOrRight().circle, opacity: ".5" }}
      ></div>
      <div
        className={`border-primary  border-4 ${leftOrRight().arrowClass}`}
        style={{ height: "25px", width: "25px", transform: leftOrRight().arrow }}
      ></div>
    </div>
  );
};

export default SliderArrow;
