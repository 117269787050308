import React from "react";
import { socialLinks } from "../site-data/company-info";

const SocialMediaLinks = () => {
  const renderLinks = () => {
    return socialLinks.map((el, index) => {
      return (
        <a href={el.link} key={index} alt={el.alt} className="me-2 ms-2">
          <img src={el.img} alt={el.alt} height={el.height} width={el.width} />
        </a>
      );
    });
  };

  return <div className="d-flex">{renderLinks()}</div>;
};

export default SocialMediaLinks;
