import lostLimbsTile from "../../injuryLitigationTypes/tiles/lostLimbs";
import paralysisTile from "../../injuryLitigationTypes/tiles/paralysis";
import severBurnsTile from "../../injuryLitigationTypes/tiles/severeBurns";
import spinalInjuriesTile from "../../injuryLitigationTypes/tiles/spinalInjuries";

const oilfieldInjuries = {
  slug: "oilfield-injuries",
  link: "/practice-areas/oilfield-injuries",
  image: "/img/PASlider/oilField.jpg",
  imgAlt: "image of oil derick or rig",
  imageYAlign: "40",
  title: "Oilfield Accidents",
  infoTitle: "Injury Types",
  infoTiles: [lostLimbsTile, paralysisTile, severBurnsTile, spinalInjuriesTile],
  showSlider: true,
  showProcess: true,
  showForm: true,
  description:
    "Explosions, falling objects, gas leaks, well blowouts and gushers, or collapsing rigs are areas we can help get you componsation.",
  contentTitle: "",
  content: (
    <div className="row overview ">
      <div className="h2 fw-light mb-4 ">Oilfield Injury Law Firm</div>
      <div className="h4 fw-light mb-4 mt-2">Best Oilfield Injury Lawyers in East Texas</div>
      <p className="fw-light mt-2">
        In the 1930s, Smith County emerged as the regional center of the oil and gas industry, and oil production remains an important
        aspect of our community’s economy and identity. Working in the oilfield is particularly dangerous work, and when injuries or deaths
        are caused by the negligence or wrongful act of others, Texas law provides for recovery based on personal injury or wrongful death.
        As attorneys born and raised in Texas, we understand how the oil industry operates, and the importance of protecting those who risk
        life and limb working in the field.{" "}
      </p>
      <p className="fw-light">
        Common accidents associated with the oilfield are explosions, falling objects, gas leaks, well blowouts and gushers, and collapsing
        decks and work rigs. Parties responsible for such mishaps may include employers and third parties not directly connected to one’s
        employer. Oil companies rarely own the property that they drill on; rather, they enter leases or deeds with property owners that
        temporarily allow companies to occupy land and seek oil. Therefore, oilfield workers are often injured on property that is not owned
        by their employers. If a property owner fails to inspect his or her premises for dangerous conditions, fails to make repairs, or
        neglects to provide adequate warning to others, he or she may be liable to persons injured on the unsafe property. Our attorneys
        possess the requisite knowledge of the law and personal injury claims to identify every potentially liable party.
      </p>

      <h4 className="fw-light mt-5 ">In the Event of an Injury Under These Conditions, What Should I do?</h4>
      <p className="fw-light mt-3">
        Texas law provides for recovery-based injuries or wrongful death due to the negligence or wrongful acts of others. If you find
        yourself injured from an oilfield field accident or have lost a loved one due to negligence of another party, discuss your case with
        us before letting heated emotions cause more damages. Due to the hazardous work environment, replete with heavy machinery and
        inherently dangerous tasks, oilfield injuries may be catastrophic and life changing.
      </p>
    </div>
  ),
};

export default oilfieldInjuries;

