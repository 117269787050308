export const COMPANY_NAME = "Negem & Worthington";
export const ADDRESS1 = "1828 ESE Loop 323";
export const ADDRESS_SUITE = "Suite R-1A";
export const ADDRESS_CITY = "Tyler";
export const ADDRESS_STATE = "Texas";
export const ADDRESS_ZIP = "75701";
export const COMPANY_PHONE = "903-595-4466";
export const COMPANY_FAX = "903-593-3266";
export const COMPANY_EMAIL = "GET@THIS.INFO";
export const COMPANY_LOGO = "/img/N&W-main-logo2.svg";

const FACEBOOK_LINK = "https://www.facebook.com/Negem-Worthington-Attorneys-At-Law-289564281101934/";
const TWITTER_LINK = "https://twitter.com/Negem_Law";
const YOUTUBE_LINK = "https://www.youtube.com/channel/UCN0rRycDE-toBN6LK23zEKg/videos";
const MARTINDALE_LINK = "https://www.martindale.com/organization/negem-worthington-1308108/tyler-texas-1709947-f/";
const LAWYERS_LINK = "https://www.lawyers.com/tyler/texas/negem-and-worthington-1709947-f/";

const SOCIAL_ICON_WIDTH = "30px";
const SOCIAL_ICON_HEIGHT = "30px";
export const socialLinks = [
  {
    title: "Facebook",
    alt: "Facebook link",
    link: FACEBOOK_LINK,
    img: "/img/facebook.svg",
    width: SOCIAL_ICON_WIDTH,
    height: SOCIAL_ICON_HEIGHT,
  },
  {
    title: "Twitter",
    alt: "twitter link",
    link: TWITTER_LINK,
    img: "/img/twitter.svg",
    width: SOCIAL_ICON_WIDTH,
    height: SOCIAL_ICON_HEIGHT,
  },
  {
    title: "YouTube",
    alt: "Youtube link",
    link: YOUTUBE_LINK,
    img: "/img/youtube.svg",
    width: SOCIAL_ICON_WIDTH,
    height: SOCIAL_ICON_HEIGHT,
  },
  {
    title: "Martindale.com",
    alt: "Martindale.com link",
    link: MARTINDALE_LINK,
    img: "/img/martindale.svg",
    width: SOCIAL_ICON_WIDTH,
    height: SOCIAL_ICON_HEIGHT,
  },
  {
    title: "Lawyers.com",
    alt: "Lawyers.com link",
    link: LAWYERS_LINK,
    img: "/img/lawyers.svg",
    width: SOCIAL_ICON_WIDTH,
    height: SOCIAL_ICON_HEIGHT,
  },
];


